<template>
  <div class="projects">
    <Loader v-if="isLoading" />

    <div class="container" v-if="!isLoading">
      <div class="h4">Портфель проектов</div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <button
            :key="index"
            class="swiper-slide"
            :class="activeCategory === category.id ? 'active' : ''"
            v-for="(category, index) in categoriesList"
            @click="activeCategory = category.id"
          >
            <img
              v-if="category.icon[0]"
              :src="`${API_URL}${activeCategory === category.id ? category.icon[1].url : category.icon[0].url}`"
            />
            <span>{{ category.name }}</span>
          </button>
          <button class="swiper-slide" :class="activeCategory === 0 ? 'active' : ''" @click="activeCategory = 0">
            <img :src="require(`@/assets/img/all_building${activeCategory === 0 ? '_blue' : ''}.svg`)" />
            <span>Все проекты</span>
          </button>
        </div>
      </div>
      <div class="projects-list">
        <ProjectsItem v-for="(project, i) in filteredProjectsList" :project="project" :key="i" />
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/sass/projects-list.sass';
import config from '@/config';
import { mapState } from 'vuex';
import ProjectsItem from '@/components/Projects/ProjectsItem.vue';
import Loader from '@/components/Loader.vue';
import Swiper from 'swiper';
import { FreeMode } from 'swiper/modules';

export default {
  name: 'ProjectsList',
  components: { Loader, ProjectsItem },
  data() {
    return {
      isLoading: true,
      activeCategory: 0,
      projectCategoriesSwiper: null,
    };
  },
  computed: {
    ...mapState({
      projectsList: (state) => state.projects.projectsList,
      categoriesList: (state) => state.projects.projectCategoriesList,
    }),
    filteredProjectsList() {
      const data = this.projectsList;
      if (this.activeCategory === 0) return data;
      return data.filter((project) => project.project_category?.id === this.activeCategory);
    },
    API_URL() {
      return config.API_URL;
    },
  },
  methods: {
    loadProjects() {
      this.$store.dispatch('getProjectsList').then(() => {
        this.isLoading = false;
        this.$nextTick(() => {
          this.projectCategoriesSwiper = new Swiper('.swiper-container', {
            slidesPerView: 'auto',
            spaceBetween: 70,
            grabCursor: true,
            slideToClickedSlide: true,
            freeMode: true,
            modules: [FreeMode],
            breakpoints: {
              1071: {
                spaceBetween: 70,
              },
              0: {
                spaceBetween: 30,
              },
            },
          });
        });
      });
    },
    loadData() {
      this.$store.dispatch('getProjectCategoriesList').then(this.loadProjects);
    },
  },
  mounted() {
    this.isLoading = true;
    this.loadData();
    this.$emit('setLastCrumb', 'Портфель проектов');
  },
};
</script>
