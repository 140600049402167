<template>
  <router-link :to="`/projects/${project.id}`" class="projects-item">
    <div class="projects-item__picture">
      <img :key="project.preview.url" v-lazyload="largePreviewUrl" />
      <div class="projects-item__picture-overlay"></div>
    </div>
    <div class="projects-item__city">
      Город: {{ project.city }}
    </div>
    <div class="projects-item__date">
      Год окончания строительства: {{ project.year }}
    </div>
    <div class="projects-item__name">{{ project.name }}</div>
  </router-link>
</template>

<script>
import '@/assets/sass/projects-list-item.sass';
import { getPreviewUrl } from '@/helpers';
import config from '@/config';

export default {
  name: 'ProjectsListItem',
  props: {
    project: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    API_URL() {
      return config.API_URL;
    },
    largePreviewUrl() {
      return getPreviewUrl(this.project.preview, true);
    },
  },
};
</script>
